@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes filterDoubleAnimationOut {
  from {
    transform: width("40%");
  }
  to {
    transform: width("25%");
  }
}

@keyframes filterDoubleAnimationIn {
  from {
    transform: width("0%");
  }
  to {
    transform: width("25%");
  }
}

.doubleOut {
  animation: filterDoubleAnimationOut 0.2s linear;
}

.doubleIn {
  animation: filterDoubleAnimationIn 0.2s linear;
  animation-delay: 0.3;
}

.ant-menu-submenu .ant-menu-item {
  display: flex;
  align-items: center;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  min-height: 150px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#label-file-upload:hover {
  border-color: rgb(129, 183, 252);
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #1e88e5;
  border-color: #1e88e5;
}
